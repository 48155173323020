/** @jsx jsx */
import React, { createRef, useEffect, useRef, useState } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { IBasePart, IMargins } from '../part';
import useTheme from '../../../hooks/useTheme';
import useStatefulInput from '../../../hooks/useStatefulInput';
import Clickable from '../../../components/clickable';
import Card from '../../../components/card';
import useLanguage from '../../../hooks/useLanguage';
import RemoteImage, { IRemoteImage } from '../../../components/RemoteImage';

export interface ICamCheckPart extends IBasePart {
  type: 'cam_check';

  enabledIcon: IRemoteImage;

  inputId: string;
  margins: IMargins;
  startButtonText: string;
  successAccessibilityLabel: string;
}

const CamCheckPart = ({
  enabledIcon,
  inputId,
  margins,
  startButtonText,
  successAccessibilityLabel,
}: ICamCheckPart) => {
  const { inputValue, setInputValue } = useStatefulInput<boolean>({
    inputId,
    initialValue: false,
  });

  const [checkStarted, setCheckStarted] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);

  const localVideoTrackRef = useRef<MediaStream | undefined>();
  const videoContainerElementRef = createRef<HTMLDivElement>();

  const { font, color } = useTheme();
  const language = useLanguage();

  const startCheck = () => {
    setCheckStarted(true);
    const videoContainerElement = videoContainerElementRef.current;

    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setPermissionDenied(false);
        localVideoTrackRef.current = stream;
        const videoTracks = stream.getVideoTracks();
        const videoTrack = videoTracks.length > 0 ? videoTracks[0] : null;

        if (videoContainerElement && videoTrack) {
          const videoElement = document.createElement('video');
          videoElement.srcObject = new MediaStream([videoTrack]);
          videoElement.autoplay = true;
          videoContainerElement.appendChild(videoElement);
          setInputValue(true);
        }
      })
      .catch(() => {
        setPermissionDenied(true);
        setInputValue(false);
      });
  };

  useEffect(() => {
    return () => {
      // Release the media stream when the component unmounts.
      if (localVideoTrackRef.current) {
        localVideoTrackRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  const WRAPPER_STYLE: CSSObject = {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: margins.top,
    paddingRight: margins.trailing,
    paddingBottom: margins.bottom,
    paddingLeft: margins.leading,
  };

  const START_CHECK_CARD: CSSObject = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
    margin: '0 20px',

    img: {
      height: '32px',
    },

    span: {
      marginLeft: '8px',
      fontFamily: font.FAMILY_BOLD,
      fontSize: 16,
      color: color.PRIMARY,
    },
  };

  const VIDEO_CARD_STYLE: CSSObject = {
    display: checkStarted && inputValue ? 'block' : 'none',
    position: 'relative',
    marginLeft: 25,
    marginRight: 25,

    div: {
      position: 'relative',
      overflow: 'hidden',
      borderRadius: 7,
    },

    video: {
      display: 'block',
      width: '100%',
      height: 'auto',
    },
  };

  const PERMISSION_DENIED_STYLE: CSSObject = {
    padding: '18px 20px',
    borderRadius: 16,
    backgroundColor: color.ALERT_SECONDARY,
    lineHeight: '150%',

    h3: {
      fontFamily: font.FAMILY_BOLD,
    },

    button: {
      display: 'inline-block',
      width: 'auto',
      padding: '3px 6px',
      margin: '23px -3px -6px -3px',

      fontSize: 12,
      fontFamily: font.FAMILY_BOLD,
      color: color.ALERT,
      textTransform: 'uppercase',
    },
  };

  return (
    <div css={WRAPPER_STYLE} aria-live='polite'>
      {/* Start check button */}
      {!checkStarted && !permissionDenied && (
        <Clickable styleAs='card' css={START_CHECK_CARD} onClick={startCheck}>
          <RemoteImage {...enabledIcon} tintColor={color.PRIMARY} />
          <span>{startButtonText}</span>
        </Clickable>
      )}

      {/* Video container */}
      <Card css={VIDEO_CARD_STYLE}>
        <div
          ref={videoContainerElementRef}
          aria-label={successAccessibilityLabel}
          aria-hidden={!inputValue}
        />
      </Card>

      {/* Permission denied info */}
      {permissionDenied && (
        <div css={PERMISSION_DENIED_STYLE}>
          <h3>{language.get('cam_check_part.permissions_error_title_web')}</h3>
          <div>
            {language.get('cam_check_part.permissions_error_description_web')}
          </div>

          <Clickable scale onClick={startCheck}>
            {language.get('cam_check_part.permissions_error_button_web')}
          </Clickable>
        </div>
      )}
    </div>
  );
};

export default CamCheckPart;
