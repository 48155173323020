/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { IScheduleInfoBox } from './schedule';
import useTheme from '../../../../../hooks/useTheme';
import RemoteImage from '../../../../../components/RemoteImage';

const ScheduleInfoBox = ({ text, image }: IScheduleInfoBox) => {
  const { color } = useTheme();

  return (
    <div
      css={{
        backgroundColor: color.SECONDARY,
        padding: '15px 20px',
        margin: 20,
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          fontSize: '15px',
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <RemoteImage
        {...image}
        css={{
          width: 40,
          height: 40,
        }}
      />
    </div>
  );
};

export default ScheduleInfoBox;
